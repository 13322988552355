
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: normal;
	src: local("?"), url("../resources/fonts/Lato-Hairline.ttf") format("truetype");
}
@font-face {
	font-family: "LatoLight";
	font-style: normal;
	font-weight: normal;
	src: local("?"), url("../resources/fonts/Lato-Light.ttf") format("truetype");
}
@font-face {
	font-family: "LatoRegular";
	font-style: normal;
	font-weight: normal;
	src: local("?"), url("../resources/fonts/Lato-Medium.ttf") format("truetype");
}

html, body {  
  margin:0px;  
  height:100%;  
}  

.bgLogin{
  background: url(../resources/img/bgLogin.jpeg) center center;
  background-size: cover;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.contentForm{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  margin: 0 auto;
  padding: 30px 100px;
  width: auto;
}
.contentForm img{
  width: 200px;
  margin-bottom: 20px;
}
input{
  height: 45px;
  padding: 5px  10px !important;
}

