body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --colorGTR: #dd5a12;
  --verdeGTR: #0f1d09;
}

/*Wundertec*/

/*Helpers*/
.center {
  text-align: center;
}

.p-20 {
  padding: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.full {
  width: 100%;
}

.m-10 {
  margin: 10px;
}

/*Flex*/
.rowWunder {
  display: inline-block;
  width: 100%;
}

.flexCollapse {
  display: inline-block;
  width: 49%;
}

.ant-input-password {
  padding: 0px 11px !important;
}

.botonLogin {
  background-color: var(--colorGTR);
  height: 50px;
  width: 150px !important;
}

.iconoSideH {
  width: 90px;
}

.iconoSide {
  width: 30px;
  margin-top: 10px;
}

.iconoSide2 {
  width: 30px;
}

.ant-menu-item-only-child {
  height: 100px !important;
}

.ant-layout-sider {
  background: var(--verdeGTR) !important;
}

.ant-menu-dark {
  background-color: var(--verdeGTR) !important;
}

.ant-menu-item-selected {
  background-color: var(--colorGTR) !important;
}

.ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--colorGTR) !important;
}

.ant-menu-inline-collapsed ul li:first-child img {
  margin-left: -70px;
}

.ant-layout-header {
  background-color: var(--verdeGTR) !important;
}

.botonSalir {
  align-items: center;
  background: #ff762b;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 45px;
  top: 10px;
  right: 20px;
  width: 45px;
  text-align: center;
  position: absolute;
  text-align: center;
}

.botonSalir img {
  width: 25px;
  margin: 0 auto;
}
.iconoCerrar {
  width: 20px;
  margin-top: 10px;
}

.contentFiltro {
  background-color: var(--verdeGTR);
  border-radius: 15px;
  padding: 30px 20px 40px;
}

.contentFiltro p {
  color: white;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2px;
  text-align: center;
}

.ant-select .ant-select-arrow .anticon>svg {
  fill: var(--colorGTR);
}

.ant-select-selector {
  height: 42px !important;
  padding: 6px 11px !important;
}

.ant-select-selector input {
  margin-top: 5px;
  padding-left: 0px !important;
}

.ant-picker-content thead {
  background-color: var(--colorGTR) !important;
  color: white !important;
  border-radius: 10px;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  border-radius: 5px;
  color: white !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  margin-top: 5px;
}

.contenTable {
  background-color: var(--verdeGTR);
  border-radius: 15px;
  margin-top: 50px;
  padding: 40px 20px;
}

.ant-btn-primary {
  background-color: var(--colorGTR) !important;
  height: 40px;
  width: 100px;
}

.ant-btn-default {
  height: 40px;
  width: 100px;
}

.ant-btn-default:hover {
  color: var(--colorGTR) !important;
  border-color: var(--colorGTR) !important;
}

.ant-checkbox-group span {
  color: white !important;
  font-weight: 300 !important;
}

.ant-card {
  margin: 10px;
}

.contentRow {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px;
}

.iconoInformacion {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 25px;
}

.ant-popover-inner {
  width: 250px;
}

.ant-popover-inner p {
  font-size: 12px;
  font-weight: bold;
}

.cardDashboard {
  background-color: var(--verdeGTR);
  color: white;
}

.iconoCard {
  display: block;
  width: 40px;
  margin: 0 auto 10px;
}

.ant-card-head-title {
  color: white;
  text-align: center;
  padding: 10px;
}

.cifraCard {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
}

.flechaStatus {
  width: 30px;
  vertical-align: middle;
  margin-right: 10px;
}

.iconoArrow {
  width: 30px;
  vertical-align: middle;
  margin-right: 10px;
}

.hide {
  display: none;
}

.ant-list-split .ant-list-header {
  border-block-end: 1px solid #e1e1e1 !important;
}

.ant-list-split .ant-list-item {
  border-block-end: 1px solid #e1e1e1 !important;
}

.listKey {
  background-color: var(--verdeGTR);
}

.listKey .ant-list-item {
  color: white;
  font-weight: bold;
}

.tituloLista {
  color: #dd5a12;
  font-size: 18px;
  font-weight: bold;
}

.ant-layout-header {
  height: 70px;
}

.cardWhite {
  background-color: white;
  border-radius: 10px;
}

.ant-menu-item {
  margin-top: 10px;
}

.ant-spin-container {
  background-color: inherit !important;
  border-radius: inherit !important;
  padding: inherit !important;
}

.iconoCerrar {
  margin-left: 70px;
  transition: all 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
}

.ant-layout-sider-collapsed .iconoSideH {
  margin-left: 20px;
  transition: all 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
}

.ant-menu-inline-collapsed .iconoSide2 {
  margin-top: 5px !important;
  margin-left: -5px !important;
}

.ant-card-head-title {
  text-transform: uppercase;
  /* white-space: normal; */
}

.ant-select-selector {
  margin-bottom: 10px;
}

.ant-picker {
  width: 90%;
}
.botonFiltro {
  border: 1px solid #dd5a12;
  height: 45px !important;
  width: 100% !important;
  background: #dd5a12;
  color: white;
  font-weight: bold;
}
.botonFiltro:hover {
  border: 1px solid #dd5a12 !important;
  height: 45px !important;
  width: 100% !important;
  background: #f36a20 !important;
  color: white !important;
  font-weight: bold;
}
.botonFiltro img {
  width: 25px;
  margin-left: 10px;
  vertical-align: middle;
}
.ant-select-tree-title span{
  text-transform: capitalize;
}
.ant-select-tree-checkbox-disabled{
  display: none;
}
.ant-select-tree-title span{
  font-weight: bold;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 5px 20px;
  margin-bottom: 10px;
}
.ant-card-head-title {
  white-space: normal !important;
  text-overflow: clip !important;
}
.ant-tabs-tab-btn{
  color: #ffffff !important;
}
.g2-tooltip-name{
  font-weight: bold;
}
.fecha{
  color: #ffffff;
  font-size: 14px;
  float: right;
}