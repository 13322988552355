.main-bar{
    width: 100%;
    margin: 0;
    background: #0f1d09;

}

.select-option{
    background:#193A01;
    color: white;
}

.div-filter{
    margin-left: 100px;
    display: inline-block;
    width: 400px;
    padding: 5px;
    margin-left: 15px;
}

.select-filter{
    background: #dd5a12;;
    /* background: #193A01; */
    margin-left: 50px;
    color: white;
    padding: 13px;
    padding-right: 16px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
    /* border-top: 1px solid red; */
}

.icon-user{
    margin: 20px;
    margin-right: 30px;
    width: 90px;
    float: right;
}