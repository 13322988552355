@import "rsuite/dist/rsuite.css";

.dataTables_wrapper{
    background: white;
    border-radius: 10px;
    padding: 15px;
}

th{
    border: 1px solid white;

}

td{
    border-bottom: 1px solid #535353;
    font-size: 12px;
}

#dataTables_wrapper .dataTables_info {
    color: gray !important;
}

.dataTables_filter{
    color: gray !important;
}

.dataTables_wrapper .dataTables_filter {
    text-align: right;
    margin-bottom: 20px;
}
.dataTables_wrapper .dataTables_filter input {
    border: none;
    border-bottom: 1px solid  #a49c9c;
    padding: 5px;
    background-color: transparent;
    margin-left: 3px;
    height: 35px;
}
.dataTables_wrapper label {
    color: var(--colorGTR) !important;
    font-weight: bold;
}
.buttons-page-length{
    width: auto !important;
    background-color: red !important;
}
.dataTables_wrapper thead tr th {
    background: #0f1d09 !important;
    border-radius: 5px !important;
    font-weight: 400 !important;
    height: 30px !important;
    vertical-align: middle !important;
}