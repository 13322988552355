.ant-table-pagination {
    background: #f7f7f7 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0px !important;
    padding: 10px;
}

.ant-table-wrapper .ant-table-thead>tr>th {
    background-color: var(--verdeGTR) !important;
    color: white;
    letter-spacing: 1.5px;
    font-size: 13px;
    font-weight: 400;
    padding: 17px;
}

.ant-spin-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.anticon svg {
    fill: #a0874a;
}

.iconoDetail {
    cursor: pointer;
    width: 50px;
}

.ant-table-measure-row {
    display: none;
}
/* 
.ant-table-row td {
    padding: 0px !important;
} */

.ant-modal {
    width: 80% !important;
}

.ant-modal-header {
    background: var(--verdeGTR) !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    color: white !important;
    padding: 15px;
    margin-bottom: 0px !important;
}

.ant-modal-title {
    color: white !important;
    letter-spacing: 1.5px;
}

.ant-modal-content {
    padding: 0px !important;
}

.ant-modal-footer {
    background: #f7f7f7 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px !important;
    margin-top: 0px !important;
}

.headModal {
    background: url(../../resources/img/body-background.jpeg) center center;
    background-size: cover;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}

.headModal img {
    width: 150px;
    margin-right: 50px;
}

.blockSpan {
    margin-right: 30px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 300;
}

.ant-tabs-nav {
    background-color: var(--verdeGTR);
    padding: 10px;
    color: white;
    letter-spacing: 1px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #eeff2b !important;
}

.ant-tabs-ink-bar {
    background: #eeff2b !important;
}

.ant-tabs .ant-tabs-tab:hover {
    color: #eeff2b;
}

.ant-collapse-content-box {
    padding: 5px 20px;
}

.ant-collapse-content-box p {
    margin-bottom: 0px;
}

.ant-list-items {
    background: #0f1d09;
    color: white;
    border-radius: 5px;
}
.ant-list-item{
    border-block-end: 1px solid #fff !important;
    color: white !important;
    padding: 10px !important; 
} 
.ant-list-item span{
    color: #eeff2b;
    font-weight: 600;
}
.ant-list-item strong{
    color: #eeff2b;
    font-weight: 500;
}
.imagenList{
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
}
.imagenList img{
    border-radius: 10px;
    width: 100%;
}

.spinnerloading{
    margin: auto;
    margin-top: 30px;
    display: flex;
    margin-bottom: 30px;
}

.spinnerloading::after {
    content: "";
    margin: auto;
    width:70px;
    height: 70px;
    border: 5px solid #2c422d;
    border-top-color: #f9a801;
    border-radius: 50%;
    animation: spinerloading 1s linear infinite;

}

@keyframes spinerloading{
    to {
        transform: rotate(1turn);  
    }
}