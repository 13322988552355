
.my-modal{
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    width: 80%;
    overflow-y: scroll;
    height: 800px;
    z-index: 10;
    left: 13vh;
    position: fixed;
    top: 4vh;
    left: calc(50 - 15rem);
}

.background-modal{
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;;
}

.hr-product {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #bd2525;
  }

.bg-mbody{
    border-radius: 0px;
   padding: 0px;
}
.header-modal {
    /* background-color: #ff4600 !important;
    color: white; */
    background-color: #ff4600 !important;
    color: white !important;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px;
}
#headingOne {
    background: transparent;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.55);
    height: auto;
    font-size: 14px;
    text-align: left;
    padding-top: 10px;
    font-family: "latoLight";
}
.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0px;
}
.span-detail{
    font-family: "latoRegular";
}
label {
    font-family: "latoLight";
    font-weight: 100;
    font-size: 13px;
    letter-spacing: 1px;
}
.nav-tabs{
    position: relative;
    height: auto;
    background: transparent;
    box-shadow: 0 0 0px;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    /* background-color: #fe4601;
    color: white !important; */
    background-color: rgb(232, 193, 126)!important;
    color: black!important;
    cursor: default;
    border: none;
    border-bottom-color: transparent;
    border-radius: 0px;
}
.btn-minimalistic{
    /* background-color: #fe4601 !important;
    border:#fe4601 !important;
    color: white !important; */
    background-color: #fe4601 !important;
    border:#fe4601 !important;
    color: white !important;
}
.nav-tabs > li > a {
    color: white !important;
}
.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    border: none !important;
    transition: all 300ms cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
    transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
}
.panel-group {
    margin-bottom: 0px;
}
.list-unstyled {
    font-size: 16px;
    height: auto;
}
.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    letter-spacing: 1px;
    font-size: 13px;
}   
.list-unstyled {
    font-size: 14px;
    height: auto;
    letter-spacing: .5px;
}
.icon-clos{
    width: 25px;
    float: right;
}
.titulo-imagen{
    /* background: #fe4601;
    color: white !important;  */
    background-color: #fe4601 !important;
    color: white !important;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px;
    text-transform: none !important;
    font-family: "latoLight";
}

.icon-clos-tab{
    float: right;
    width: 20px; 
    margin-left: 2px;
}

.botonPerformance{
    background: #162c18;
    /* position: absolute; */

}

.eye-image{
    width: 15px;
}

.botonPerformance:hover{
    background: #ccc;

}

.iconoCloseModal{
    width: 30px;
    right: 30px;
    top: 15px;
    position: absolute;
}

/* #tablaVenueDetail{
    table-layout: fixed; 
    word-wrap:break-word; 
  } */
#tablaVenueDetail{
    overflow:auto;
}

.dataTables_scroll
{
    overflow:auto;
}


.cell-size{
    width: 60px;
}

.excel-export-button-container{
    margin-right: 0;
}

.excel-button{
    width: 78px;
    margin-left: auto;
    text-align: center;
    margin-right: 17px;
    padding: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    border-radius: 7%;
    background: #ff4600;
    cursor: pointer;
}

.excel-button:hover{

    background-color: #ccc;
    color: black;
}

.button-radio{
    display: inline-block;
    margin-right: 13px;
}

.radio-buttons-container{
    margin-top: 5px;
}

.photo-modal{
    width: 100%; 
    height: 350px;
/*    cursor: zoom-in;*/
}

#fullpage-img {
    display: none;
    position: absolute;
    text-align: center;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* padding-top: 10px; */
    /* justify-content: center;
    align-items: center; */
    cursor: zoom-out;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-color: black;
  }

  #fullpage-img div{
    margin: auto;
    vertical-align: middle;
  }

  #fullpage-img div img{
    height: 100vh;
  }


  .radio-button-sku{
    accent-color: #fe4601;
  }

  .radio-button-sku[disabled]{
    accent-color: #fe4601;

  }

  .panel-photo-title{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: "Montserrat",sans-serif;
    color: white !important;
    margin-bottom: 20px;
  }

 .display-photo-section-img{
    margin-top: -5px;
    width: 31px;
 } 


 .dt-button{
    width: 81px;
    padding: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-color: #2b5a2f;
    background: #2b5a2f;
    color: white;
    border-radius: 7px;
 }

 .dt-buttons{
    width: 16.67%;
    display: inline-block;
 }


 .dataTables_length{
    width: 15%;
    display: inline-block;
 }

 .dataTables_filter{
    float: right;
 }

 @media  (max-width: 1498px) {
    .dataTables_length{
        width: 20%;
    }

}

@media  (max-width: 1008px) {
    .dataTables_length{
        width: 25%;
    }

}


@media  (max-width: 760px) {
    .dataTables_length{
        width: 31%;
    }

}

@media  (max-width: 618px) {
    .dataTables_length{
        display: block;
    }

}

.containerButtonsFooterModal{
    padding-top: 10px;
}

#modalDetailsCharts .modal-dialog{
    width: 80%;
}

.header-modal{
    background-color: #ff4600 !important;
    color: white;
}

.modal-header {
    background: url(../../resources/img/body-background.jpg) center center;
    background-size: cover;
    color: white;
}

#modalNewVenue, #modalEditVenue{
    position: absolute !important;
  }


  .closeModal {
    color: white;
  }
  

.modal-open {
    overflow: hidden;
  }
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
         -o-transition:      -o-transform .3s ease-out;
            transition:         transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
        -ms-transform: translate(0, -25%);
         -o-transform: translate(0, -25%);
            transform: translate(0, -25%);
  }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
         -o-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }
  .modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  .modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5;
  }
  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
  }
  .modal-header .close {
    margin-top: -2px;
  }
  .modal-title {
    margin: 0;
    line-height: 1.42857143;
  }
  .modal-body {
    position: relative;
    padding: 15px;
  }
  .modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
  .modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
  }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
  }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;
      margin: 30px auto;
    }
    .modal-content {
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
              box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    }
    .modal-sm {
      width: 300px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg {
      width: 900px;
    }
  }



/*Helpers*/
body{
  overflow-x: hidden;
  padding-right: 0% !important;
}
.center{
    text-align: center;
}
.padding-0{
  padding: 0px;
}
.bottom-10{
    margin-bottom: 10px;
}
.bottom-20{
    margin-bottom: 20px;
}
.bottom-30{
    margin-bottom: 30px;
}
.bottom-50{
    margin-bottom: 50px;
}
.bottom-100{
    margin-bottom: 100px;
}
.top-10{
    margin-top: 10px;
}
.top-20{
    margin-top: 20px;
}
.top-30{
    margin-top: 30px;
}
.top-50{
    margin-top: 50px;
}
.top-100{
    margin-top: 100px;
}
/*Helpers*/

*,
*:before,
*:after {
  box-sizing: inherit;
}

html{
    height: 100%;
    box-sizing: border-box;
}

body{
  /* background-color: #E9E4C7; -- #393b3e */
  background: url(../../resources/img/body-background.jpg) center center;
  /* background-size: cover; */
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 100px;
}

footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 50px;
  padding: 1rem;
  background-color: black;
  text-align: right;
  color: white;
  z-index: 10;
}

tspan{
    text-decoration:none;
    text-shadow:none !important;
}

.linkFooter{
    color: #CCDA5D !important;
}


@keyframes kenBurns {
    0% {
      transform: scale(1);
      transform: translate(0px, 0px);
    }
    100% {
      transform: scale(1.15);
      transform: translate(-50px, -50px);
    }
  }
  

.forgot{
    font-size: 12px;
}
.forgot:hover{
    font-size: 12px;
    text-decoration: none;
}





.btn-report{
    cursor: context-menu !important;
}

.btn-report.btn-success{
    background: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #5cb85c !important;
    color: #5cb85c !important;
    font-weight: bolder;
}

.btn-report.btn-danger{
    background: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #d9534f !important;
    color: #d9534f !important;
    font-weight: bolder;
}

.btn-report.btn-default{
    background: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid lightgrey !important;
    color: #404040 !important;
}

.btn-report.btn-description{
    width: 120px !important;
    text-align: left !important;
    padding-left: 5px !important;
    overflow: hidden !important;
}

.btn-report.btn-resp{
    width: 70px !important;
    text-align: left !important;
    padding-left: 5px !important;
    overflow: hidden !important;
}

#titleLogin, #titleRecover{
}

#testPortal{
    padding-top: 50px;
}

#loginContainer{
    margin: 20% auto 0;
    min-height: 100%;
    padding: 30px;
    width: 60%;
}

.text-white{
    color: white !important;
}

.inputLogin, .inputConfirm{
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    outline: none;
    font-size: 14px;
    color: white;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill:active, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: transparent !important;
    color: white !important;
    border: none;
    border-bottom: 1px solid white;
    -webkit-text-fill-color: gray;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.inputLogin::-webkit-input-placeholder{
    color: white !important;
    opacity: .5;
}
.inputLogin::-moz-placeholder{
    color: white !important;
}
.inputLogin:-ms-input-placeholder{
    color: white !important;
}
.inputLogin:-moz-placeholder{
    color: white !important;
}

#submitLogin, #submitRecoverPassword, #submitConfirmAccount{
    width: 100%;
    height: 50px;
    border: white;
    background: #ff4600;
    color: white;
    font-size: 18px;
    font-weight: bold;
    outline: none;
    margin-top: 40px;
    -webkit-transition: all 300ms cubic-bezier(0.500, 0.250, 0.500, 0.750);
   -moz-transition: all 300ms cubic-bezier(0.500, 0.250, 0.500, 0.750);
     -o-transition: all 300ms cubic-bezier(0.500, 0.250, 0.500, 0.750);
        transition: all 300ms cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */

-webkit-transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750);
   -moz-transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750);
     -o-transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750);
        transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
}

#submitLogin:hover, #submitRecoverPassword:hover{
    background: #ff6a34;
    -webkit-box-shadow: 0px 0px 13px 1px rgba(255,70,0,1);
    -moz-box-shadow: 0px 0px 13px 1px rgba(255,70,0,1);
    box-shadow: 0px 0px 13px 1px rgba(255,70,0,1);
}

#forgotPasswordLink, #returnToLoginLink, #errorMessageLogin, #errorMessagePassword, #goToCategory{
    color: white;
}

.text-latoRegular{

}

.text-latoLight{

}

.text-lato{

}

table thead tr th{
    background: transparent;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

table tbody tr td{
    color: #2d2d2d;
        font-size: 15px;
    border: 1px solid white;
}

#calendar h2, #calendarTasks h2{
}

.dataTables_wrapper label{
    color: white;
}
.dataTables_info{
    color: white;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #193A01;
    border-color: #193A01;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    z-index: 2;
    color: #193A01;
    background-color: #eee;
    border-color: #ddd;
}
.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #193A01;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.dataTables_wrapper select, .dataTables_wrapper input{
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
    color: white;
    outline: none !important;
    box-shadow: none !important;
}

.dataTables_wrapper select:focus, .dataTables_wrapper input:focus{
    border-bottom: 1px solid #193A01;
}

.dataTables_wrapper select option{
    color: #393B3E;
}

#containerProfileImage{
    background: white;
    border: 1px solid white;
    border-bottom-right-radius: 10px;
}

#containerGeneralInfo{
    border: none;
    border-right: 1px solid #ff4600;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 10px;
}

#containerGeneralInfo ul li{
    font-size: 20px;
}

#profileImage{
    width: 100%;
}

.containerSquaresDashboard{
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.squareDashboard{
    height: 200px;
    border: 1px solid #3b5238;
    text-align: center;
    background: rgba(0,0,0,0.5);
}

.containerTitleSquare{
    padding-top: 12px;
    height: 60px;
    background: rgba(25, 58, 1, 0.5);
}

.numberSquare{
    color: #3b5238;
}

.squareDashboardSecondary{
    height: 200px;
    border: 1px solid white;
    text-align: center;
    padding-top: 10px;
    background: #2B908F;
}

.numberSquare{
    font-size: 50px;
}

.groupButtonsTitle{
    margin-top: 15px;
}

.groupButtonsTitle button{
    border: 1px solid #dd5a12;
    outline: none;
    background: #dd5a12;
    color: white;
    width: 70px;
}

.groupButtonsTitle button:hover, .groupButtonsTitle button:active, .groupButtonsTitle button:focus{
    background: #e16a28 !important;
    color: white !important;
    outline: none !important;
}

label{
    font-weight: normal;
}

.input-form{
    border: none;
    border-bottom: 1px solid #b9b7b7;
    outline: none;
    border-radius: 0;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-form:focus, .input-form:active{
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #FE4600;
}

.btn-minimalistic{
    border: 1px solid #dd5a12;
    outline: none;
    background: #dd5a12;
    color: white; 
}

.btn-minimalistic-danger{
    outline: none;
    background: #ff4d4d;
    color: white;
    border: 1px solid red;
}

.btn-minimalistic:hover, .btn-minimalistic:active, .btn-minimalistic:focus{
    border: 1px solid white;
    background: #e16a28 !important;
    color: white !important;
    outline: none !important;
}

.deleteAdded{
    cursor: pointer;
}

.alert-minimalistic-danger{
    border: none;
    border-bottom: 1px solid #ff4545;
    border-radius: 0px;
    background: #f7cbcb;
    text-align: center;
    margin-bottom: 5px;
}

.containerButtonsFooterModal{
    padding-top: 10px;
}

.form-error{
    color: #a94442;
}

.form-error input, .form-error select, .form-error textarea{
    border-bottom: 1px solid red;
}

.selectFilters{
    color: gray;
}

.fc-day{
    cursor: pointer !important;
}
.linkProfile{
    color: gray !important;
}
.no-resize{
    resize: none !important;
}

#tableDetailsChart_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tableDetailsChart_wrapper label{
    color: gray;
}
#tableDetailsChart_wrapper .dataTables_info{
    color: gray;
}

#tableDetailsChart_wrapper select, #tableDetailsChart_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableDetailsChart_wrapper select:focus, #tableDetailsChart_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tableDetailsChart_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tableDetailsChart_wrapper .buttons-html5:hover, #tableDetailsChart_wrapper .buttons-html5:active, #tableDetailsChart_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}



.dataTables_wrapper label{
    color: gray;
}
.dataTables_wrapper .dataTables_info{
    color: gray;
}

.dataTables_wrapper select, .dataTables_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

.dataTables_wrapper select:focus, .dataTables_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

.dataTables_wrapper .buttons-html5{
    border: 1px solid #ff895d;
    outline: none;
    background: transparent;
    color: white; 
    -webkit-box-shadow: 0px 0px 14px 0px rgba(255,137,93,1);
-moz-box-shadow: 0px 0px 14px 0px rgba(255,137,93,1);
box-shadow: 0px 0px 14px 0px rgba(255,137,93,1);
}

.dataTables_wrapper .buttons-html5:hover, .dataTables_wrapper .buttons-html5:active, .dataTables_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableVenues_wrapper .buttons-html5:hover, #tableVenues_wrapper .buttons-html5:active, #tableVenues_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#bestPromotersTable_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#bestPromotersTable_wrapper label{
    color: gray;
}
#bestPromotersTable_wrapper .dataTables_info{
    color: gray;
}

#bestPromotersTable_wrapper select, #bestPromotersTable_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#bestPromotersTable_wrapper select:focus, #bestPromotersTable_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#bestPromotersTable_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#bestPromotersTable_wrapper .buttons-html5:hover, #bestPromotersTable_wrapper .buttons-html5:active, #bestPromotersTable_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#brsEvaluationTableRoberta_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#brsEvaluationTableRoberta_wrapper label{
    color: gray;
}
#brsEvaluationTableRoberta_wrapper .dataTables_info{
    color: gray;
}

#brsEvaluationTableRoberta_wrapper select, #brsEvaluationTableRoberta_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#brsEvaluationTableRoberta_wrapper select:focus, #brsEvaluationTableRoberta_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#brsEvaluationTableRoberta_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#brsEvaluationTableRoberta_wrapper .buttons-html5:hover, #brsEvaluationTableRoberta_wrapper .buttons-html5:active, #brsEvaluationTableRoberta_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#brsEvaluationTableValeria_wrapper thead tr th{
    background: #ff4600;
    color: white;
    font-family: latoLight;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#brsEvaluationTableValeria_wrapper label{
    color: gray;
}
#brsEvaluationTableValeria_wrapper .dataTables_info{
    color: gray;
}

#brsEvaluationTableValeria_wrapper select, #brsEvaluationTableValeria_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#brsEvaluationTableValeria_wrapper select:focus, #brsEvaluationTableValeria_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#brsEvaluationTableValeria_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#brsEvaluationTableValeria_wrapper .buttons-html5:hover, #brsEvaluationTableValeria_wrapper .buttons-html5:active, #brsEvaluationTableValeria_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#brsEvaluationTableDiana_wrapper thead tr th{
    background: #ff4600;
    color: white;
    font-family: latoLight;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#brsEvaluationTableDiana_wrapper label{
    color: gray;
}
#brsEvaluationTableDiana_wrapper .dataTables_info{
    color: gray;
}

#brsEvaluationTableDiana_wrapper select, #brsEvaluationTableDiana_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#brsEvaluationTableDiana_wrapper select:focus, #brsEvaluationTableDiana_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#brsEvaluationTableDiana_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#brsEvaluationTableDiana_wrapper .buttons-html5:hover, #brsEvaluationTableDiana_wrapper .buttons-html5:active, #brsEvaluationTableDiana_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tasksTable_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tasksTable_wrapper label{
    color: gray;
}
#tasksTable_wrapper .dataTables_info{
    color: gray;
}

#tasksTable_wrapper select, #tasksTable_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tasksTable_wrapper select:focus, #tasksTable_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tasksTable_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tasksTable_wrapper .buttons-html5:hover, #tasksTable_wrapper .buttons-html5:active, #tasksTable_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#pendingsTable_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#pendingsTable_wrapper label{
    color: gray;
}
#pendingsTable_wrapper .dataTables_info{
    color: gray;
}

#pendingsTable_wrapper select, #pendingsTable_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#pendingsTable_wrapper select:focus, #pendingsTable_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#pendingsTable_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#pendingsTable_wrapper .buttons-html5:hover, #pendingsTable_wrapper .buttons-html5:active, #pendingsTable_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableVenuesEnergy_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tableVenuesEnergy_wrapper label{
    color: gray;
}
#tableVenuesEnergy_wrapper .dataTables_info{
    color: gray;
}

#tableVenuesEnergy_wrapper select, #tableVenuesEnergy_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableVenuesEnergy_wrapper select:focus, #tableVenuesEnergy_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tableVenuesEnergy_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tableVenuesEnergy_wrapper .buttons-html5:hover, #tableVenuesEnergy_wrapper .buttons-html5:active, #tableVenuesEnergy_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableVenuesEnergySelected_wrapper thead tr th{
    background: #ff4600;
    color: white;
    font-family: latoLight;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tableVenuesEnergySelected_wrapper label{
    color: gray;
}
#tableVenuesEnergySelected_wrapper .dataTables_info{
    color: gray;
}

#tableVenuesEnergySelected_wrapper select, #tableVenuesEnergySelected_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableVenuesEnergySelected_wrapper select:focus, #tableVenuesEnergySelected_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tableVenuesEnergySelected_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tableVenuesEnergySelected_wrapper .buttons-html5:hover, #tableVenuesEnergySelected_wrapper .buttons-html5:active, #tableVenuesEnergySelected_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableOnTradeReports_wrapper thead tr th, #tableOffTradeReports_wrapper thead tr th, #tableVenues_wrapper thead tr th, #tableUsers_wrapper thead tr th{
    background: #193A01 !important;
    color: white;
    font-family: latoLight;
    text-align: left !important;
    border: 1px solid white;
    font-size: 14px;    
    padding: 15px !important;
}

#tableOnTradeReports_wrapper tbody tr td, #tableOffTradeReports_wrapper tbody tr td{
    padding: 10px !important;
}

#tableOnTradeReports_wrapper tbody tr.odd, #tableOffTradeReports_wrapper tbody tr.odd{
    background-color: #F1F1F3;
}

table.dataTable thead .sorting:after, .sorting_desc:after, .sorting_asc:after{
    background-image: url('../../resources/img/Jager\ web-11.png');
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    content: "" !important;
    opacity: 1 !important;
    top: 12px !important;
}

#tableOnTradeReports_wrapper, #tableOffTradeReports_wrapper{
    background-color: white;
    padding: 15px;
}

#tableOnTradeReports_wrapper label, #tableOffTradeReports_wrapper label{
    color: gray;
}
#tableOnTradeReports_wrapper .dataTables_info, #tableOffTradeReports_wrapper .dataTables_info{
    color: gray;
}

#tableOnTradeReports_wrapper select, #tableOnTradeReports_wrapper input, #tableOffTradeReports_wrapper select, #tableOffTradeReports_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableOnTradeReports_wrapper select:focus, #tableOnTradeReports_wrapper input:focus, #tableOffTradeReports_wrapper select:focus, #tableOffTradeReports_wrapper input:focus{
    border-bottom: 1px solid #193A01 !important;
}

#tableOnTradeReports_wrapper .buttons-html5, #tableOffTradeReports_wrapper .buttons-html5{
    border: 1px solid #193A01 !important;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tableOnTradeReports_wrapper .buttons-html5:hover, #tableOnTradeReports_wrapper .buttons-html5:active, #tableOnTradeReports_wrapper .buttons-html5:focus,
#tableOffTradeReports_wrapper .buttons-html5:hover, #tableOffTradeReports_wrapper .buttons-html5:active, #tableOffTradeReports_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableOffTradeReports_wrapper thead tr th{
    background: #ff4600;
    color: white;
    font-family: latoLight;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tableOffTradeReports_wrapper label{
    color: gray;
}
#tableOffTradeReports_wrapper .dataTables_info{
    color: gray;
}

#tableOffTradeReports_wrapper select, #tableOffTradeReports_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableOffTradeReports_wrapper select:focus, #tableOffTradeReports_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tableOffTradeReports_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tableOffTradeReports_wrapper .buttons-html5:hover, #tableOffTradeReports_wrapper .buttons-html5:active, #tableOffTradeReports_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tablePromos_wrapper thead tr th{
    background: #ff4600;
    color: white;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tablePromos_wrapper label{
    color: gray;
}
#tablePromos_wrapper .dataTables_info{
    color: gray;
}

#tablePromos_wrapper select, #tablePromos_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tablePromos_wrapper select:focus, #tablePromos_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tablePromos_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tablePromos_wrapper .buttons-html5:hover, #tablePromos_wrapper .buttons-html5:active, #tablePromos_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableVenues_wrapper, #tableUsers_wrapper{
    background-color: white;
    padding: 15px;
}

#tableVenues_wrapper tbody tr td, #tableUsers_wrapper tbody tr td{
    padding: 10px !important;
}

#tableVenues_wrapper tbody tr.odd, #tableUsers_wrapper tbody tr.odd{
    background-color: #F1F1F3;
}

#tableVenues_wrapper label{
    color: gray;
}
#tableVenues_wrapper .dataTables_info{
    color: gray;
}

#tableVenues_wrapper select, #tableVenues_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableVenues_wrapper select:focus, #tableVenues_wrapper input:focus{
    border-bottom: 1px solid #193A01 !important;
}

#tableVenues_wrapper .buttons-html5, #tableUsers_wrapper .buttons-html5{
    border: 1px solid #193A01;
    outline: none;
    background: transparent;
    color: #193A01; 
}

#tableVenues_wrapper .buttons-html5:hover, #tableVenues_wrapper .buttons-html5:active, #tableVenues_wrapper .buttons-html5:focus,
#tableUsers_wrapper .buttons-html5:hover, #tableUsers_wrapper .buttons-html5:active, #tableUsers_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

#tableUsers_wrapper thead tr th{
    background: #ff4600;
    color: white;
    font-family: latoLight;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tableUsers_wrapper label{
    color: gray;
}
#tableUsers_wrapper .dataTables_info{
    color: gray;
}

#tableUsers_wrapper select, #tableUsers_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tableUsers_wrapper select:focus, #tableUsers_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}


#tablePromotionTeam_wrapper thead tr th{
    background: #ff4600;
    color: white;
    font-family: latoLight;
    text-align: center;
    border: 1px solid white;
    font-size: 14px;
}

#tablePromotionTeam_wrapper label{
    color: gray;
}
#tablePromotionTeam_wrapper .dataTables_info{
    color: gray;
}

#tablePromotionTeam_wrapper select, #tablePromotionTeam_wrapper input{
    border-bottom: 1px solid gray;
    color: gray;
}

#tablePromotionTeam_wrapper select:focus, #tablePromotionTeam_wrapper input:focus{
    border-bottom: 1px solid #ff4600;
}

#tablePromotionTeam_wrapper .buttons-html5{
    border: 1px solid #ff4600;
    outline: none;
    background: transparent;
    color: #ff895d; 
}

#tablePromotionTeam_wrapper .buttons-html5:hover, #tablePromotionTeam_wrapper .buttons-html5:active, #tablePromotionTeam_wrapper .buttons-html5:focus{
    border: 1px solid white;
    background: black !important;
    color: white !important;
    outline: none !important;
}

@keyframes blink {
    0% { box-shadow: 0px 0px 15px 1px rgba(255,255,255,0.6); }
    50% { box-shadow: none; }
    100% { box-shadow: 0px 0px 15px 1px rgba(255,255,255,0.6); }
}

@-webkit-keyframes blink {
    0% { box-shadow: 0px 0px 15px 1px rgba(255,255,255,0.6); }
    50% { box-shadow: 0 0 0; }
    100% { box-shadow: 0px 0px 15px 1px rgba(255,255,255,0.6); }
}

.blink {
    -webkit-animation: blink 1.5s linear 2;
    -moz-animation: blink 1.5s linear 2;
    -ms-animation: blink 1.5s linear 2;
    -o-animation: blink 1.5s linear 2;
    animation: blink 1.5s linear 2;
}

.panel-minimalistic{
    /* border: 1px solid #ff4600 */
}

.panel-minimalistic, .panel-heading{
    cursor: pointer;
    /* background: #ff6931; */
    background: url(../../resources/img/body-background.jpg) center center;
    color: white;
}


#headingOne{
    background-size: 100% auto !important;
    background: url('../../resources/img/Back_Profile_Picture_1_filter.jpg');
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.55);
    height: 200px;
    font-size: 30px;
    text-align: center;
    padding-top: 80px;
}


.carousel-indicators li{
    background-color: gray !important;
    bottom: -70px !important;
}

.panel-minimalistic .panel-body{
    /* border-top: 1px solid #ff4600 !important; */
}

.badge-success{
    background: #5cb85c;
}

.badge-danger{
    background: #d9534f;
}

#tableOnTradeReports tbody tr, #tableOffTradeReports tbody tr, #tablePromos tbody tr{
    cursor: pointer;
} 

.datepicker th{
    background: black !important;
    border-radius: 0 !important;
}

.has-error .text-white{
    color: #a94442 !important;
}

.containerFlagEvent{
    background: black;
    height: 300px;
}

.imageFlagEvent{
    width: 100%;
    height: 300px;
}

.colWithoutPadding{
    padding-left: 0;
    padding-right: 0;
}

.linkToCountryEvent{
    cursor: pointer;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
    width: 100% !important;
}

.imageBackgroundEvent{
    width: 100%;
    -webkit-filter: sepia(100%);
}

.colOverflowHidden{
    overflow: hidden;
}

.containerInfoEvent{
    position: absolute;
    background: rgba(0, 0, 0, 0.34);
    color: white;
    font-size: 20px;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    -webkit-transition: all 200ms ease-out 0s;
    -moz-transition: all 200ms ease-out 0s;
    -o-transition: all 200ms ease-out 0s;
    transition: all 200ms ease-out 0s;
}

.containerInfoEvent:hover{
    cursor: pointer;
    background: rgba(0, 0, 0, 0.80);
}

.listInfoEvent{
    -webkit-transition: all 500ms ease-out 0s;
    -moz-transition: all 500ms ease-out 0s;
    -o-transition: all 500ms ease-out 0s;
    transition: all 500ms ease-out 0s;
}

.spanVisitCountryEvent{
    margin-top: 10%;
}

.width100{
    width: 100% !important;
}

.rowImage{
    border-right: 1px solid #b5b5b5;
}

.linkDeleteImage{
    color: #bbb6b6;
}

.linkDeleteImage:hover, .linkDeleteImage:active, .linkDeleteImage:focus{
    color: #f77949;
}

.fileinput-button input {
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    width: 100px;
    height: 27px;
    margin-top: -27px;
}

.rowPhotoDeleted{
    background: #eaeaea;
}

.form-errorArea{
    border-bottom: 1px solid red !important;
}

#tableDetailsChart tr{
    cursor: pointer;
}

.no-padding{
    padding: 0 !important;
}

.height100{
    height: 100% !important;
}

#map{
    height: 100%;
    width: 100%;
}

.imagesEventsReportsContainer{
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

#bestPromotersTable tbody tr td{
    cursor: pointer;
}

#modalDetailsCharts .modal-dialog{
    width: 80%;
}

.squareDashboardBrsEvaluation{
    cursor: pointer;
}

.squareDashboardBrsEvaluation .numberSquare:hover{
    text-decoration: underline; 
}

.nav-tabs > li > a{
    color: #ff4600 !important;
}

#messageTasks{
    font-size: 15px;
}

#includeTasks{
    width: 100%;
}

.tdTaskSelect{
    cursor: pointer;
}

.tdTaskSelect.selected{
    color: #1b83cd;
    background: #c1ddff !important;
}

.selectSquare{
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
    color: white;
    outline: none !important;
    box-shadow: none !important;
    width: 80%;
    margin: 0 auto;
}

.selectSquare option{
    color: #545454;
}

.imgProfileEvaluation{
    width: 100%;
}

.squareDashboardWhite{
    background: rgba(0,0,0,0.5) !important;
}

.containerImgProfileEvaluation{
    width: 100px;
    margin: 0 auto;
    margin-top: 20px;
}

.text-orange{
    color: #ff4600 !important;
}

.squareDashboardSuccess{
    background: #A9FF97 !important;
}

.text-darkGreen{
    color: #426942 !important;
}

.squareDashboardWarning{
    background: #fff397 !important;    
}

.text-darkOrange{
    color: #825a30 !important;
}

/* .squareDashboardDanger{
    background: #F2DEDE !important;
} */

.text-darkRed{
    color: #a94442 !important;
}

.fa-circle, .fa-check{
    float: right !important; 
    color: chartreuse !important;
}

.fa-close{
    float: right !important; 
    color: red !important;
}

#filtersRow {
    background-color: rgba(25, 58, 1, 0.4);
    margin: auto;
    padding-top: 15px;
}
#filtersRow .btn-group > .btn:first-child, #filtersRow .btn-group > .btn:last-child{
    /* border-color: #ff4600 !important; */
    height: 40px;
}

#filtersRow input{
    /* border-color: #ff4600 !important; */
    height: 40px;
}

#filtersRowOff {
  background: url(../../resources/img/body-background.jpg);
  margin: auto;
  padding-top: 15px;
}

#filtersRowOff .btn-group > .btn:first-child, #filtersRowOff .btn-group > .btn:last-child{
  /* border-color: #ff4600 !important; */
  height: 40px;
}

#filtersRowOff input{
  /* border-color: #ff4600 !important; */
  height: 40px;
}

#filterReports{
    height: 40px !important;
    width: 40px !important;
}

#calendarEditTasks{
    background-color: white;
    margin: 15px;
}

.listSku li > span{
    float: right !important;
}

#statistics {
    margin-left: 9%;
    margin-right: 9%;
    background: radial-gradient(black, transparent);
    padding: 15px;
}
.titulo-graficas{
    color: white;
    padding: 0 0 15px 15px;
}
.header-modal{
    background-color: #ff4600 !important;
    color: white;
}
.list-unstyled {
    font-size: 16px;
    /* color: #333; */
    height: 380px;
}
.carousel {
    padding-bottom: 50px;
}
.carousel-indicators .active {
    width: 14px;
    height: 14px;
    margin: 0;
    background-color: #ff6931 !important;
}
.carousel-indicators li {
    margin: 2px;
}
.carousel-indicators li {
    background-color: #fc9671 !important;
}
@media screen and (min-width: 768px){
    .carousel-indicators {
        bottom: 10px;
    }
}

.brstable{
  color: white;
}
.brstable > thead > tr > th {
    background-color: #dd5a12 !important;
    border: 1px solid rgba(255,255,255,0.5);
}

.brstable > tbody > tr > td{
  border: 1px solid rgba(255,255,255,0.5);
}

.brstable tbody tr td {
  color: white !important;
}

.brstable.dataTable thead .sorting:after, .brstable .sorting_asc:after, .brstable .sorting_desc:after{
    top: 5px !important;
}



.imgUserProfile{
    width: 15%;
    display: block;
    margin: auto;
    top: 0;
    bottom: 0;
    margin-left: 40%;
    position: absolute;

}







  .modal-header {
    background: url(../../resources/img/body-background.jpg) center center;
    background-size: cover;
    color: white;
}

  .glitch-hover {
    display: inline-block;
    overflow: hidden;
    /* -webkit-filter: grayscale(1) contrast(130%);
    filter: grayscale(0) contrast(130%); */
    right: 0px;  
    top: 60%;
    position: absolute;
  }
  .glitch-hover img{
    opacity: 0.5;
    height: 430px;
    width: 430px;
  }
  .glitch-hover > img:nth-child(2), .glitch-hover > img:nth-child(3) {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  
  .glitch-hover:hover {
    cursor: pointer;
  }
  



.highcharts-background{
    background-color:red !important;
}


/*glitch banner*/

.image-glitch {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }

  
  #base {
    z-index: 0;
  }
  
  #red {
    background-color: rgba(0, 128, 0, 0.16);
    background-blend-mode: hue;
  }
  
  #cyan {
    background-color: rgba(0, 255, 158, 0.25);
    background-blend-mode: darken;
  }
  
  #transparent {
    background-color: transparent;
    background-blend-mode: unset;
  }
  

  


.fc-day-grid-event > .fc-content {
  white-space: nowrap;
  overflow: hidden;
  background: #f37733;
  border: none !important;
}

.fc-event {
  background-color: #f37733 !important;
  border: none !important;
  font-size: 12px !important;
  padding: 5px !important;
}

#calendarEditTasks {
  background-color: rgba(0, 255, 158, 0.25);
  color: white;
  padding: 20px;
  margin: 15px;
}
.fc-unthemed .fc-today {
  background: rgba(6, 30, 5, 0.6) !important;
}
.fc-state-default {
  background-color: #f37733 !important;
  background-image: linear-gradient(to bottom, #f37733, #f37733) !important;
  color: white !important;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.75) !important;
}
.highcharts-title{
  fill:white !important;
}


.sidenavFilters {  
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  /* background: rgba(0,0,0,0.5); */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px; /* Place content 60px from the top */
  padding-bottom: 50px;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  padding-right: 10px;
  padding-left: 10px;
}
.sidenavFilters .row{
  /* border: 1px solid rgba(215, 177, 111, 0.5); */
  padding: 10px 0;
  margin: 10px 0;
}
.sidenavFilters .row .form-group{
  margin-bottom: 0px;
}

.sidenavFilters .bootstrap-select>.dropdown-toggle{
  background: transparent;
  color: white;
  border: 1px solid rgba(215, 177, 111, 0.5);
}

.sidenavFilters .bootstrap-select.btn-group .dropdown-toggle .caret {
  color: #d7b16f;
}
.sidenavFilters .btn-minimalistic{
  max-width: 50%;
  margin-top: 0px !important;
  margin: 0 auto !important;
}
/* The navigation menu links */
.sidenavFilters a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;  
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenavFilters .margin-filter{
  margin-right: 0px;
}
/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenavFilters .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;  
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#report {
  transition: margin-left .5s;
  padding: 20px;
}

.margin-filter{
  margin-left: 10px;
  margin-right: 10px;
}

.lcb-size{
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
  padding-top: 8px;
}

input[type='checkbox']{
  width: 20px;
  height: 20px;  
}

.align-cb{
  text-align: center;
}

.totalVenues{
  font-size: 55px;
  color: white;
}


#perfectServeEvolutionDetailsByVenue .highcharts-title, #perfectServeEvolutionDetailsTotal .highcharts-title, 
#visibilityEvolutionDetailsByVenue .highcharts-title, #visibilityEvolutionDetailsUnits .highcharts-title, 
#listingEvolutionDetailsByVenue .highcharts-title, #listingEvolutionDetailsUnits .highcharts-title,
#activationEvolutionDetailsByVenue .highcharts-title, #activationEvolutionDetailsUnits .highcharts-title,
#trainingEvolutionDetailsByVenue .highcharts-title, #trainingEvolutionDetailsUnits .highcharts-title,
#rightPriceEvolutionDetails .highcharts-title {
  fill:white !important;
}

.subCheckbox {
  width: 15px !important;
  height: 15px !important;
}

.subLabel {
  margin-top: 4px;
}

.row-noSelect {
  margin-bottom: 15px;
}

#toShow .dropdown-menu {
  width: 100%;
}

#reportContainer .table, #tableVisibility, .dataTables_scrollHeadInner{
  width: 100% !important;
}

.rowAll {
  background: #d7b16f;
}
.dropdown-menu > li > a {
  color: #d7b16f;
  font-size: 11px;
}
.dropdown-menu > li > a:hover {
  background-color: transparent;
  color: #d7b16f;
}
.datepicker.dropdown-menu {
  width: auto;
}

.datepicker.dropdown-menu th, .datepicker.dropdown-menu td {
  padding: 4px 5px;
  color: #d7b16f;
  font-size: 12px;
}
.datepicker table tr td, .datepicker table tr th {
  border: 1px solid rgba(255,255,255,0.1) !important;
}
.datepicker table tr td.active.active{
  color: #ffffff;
  background-color: #dd5a12 !important;
  border-color: #dd5a12 !important;
}
.datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
  background: rgba(221,90,18,0.6) !important;
  
}

.kpi-reporter {
  font-weight: bold !important;
}

.subKpi-reporter {
  margin-left: 24px !important;
} 

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  vertical-align: middle;
}
.boton-filtrar-dash{
  text-align: center;
  padding-bottom: 20px;
}
#filterDashboard{
  margin-top: 10px;
  letter-spacing: 2px;
  padding: 10px 50px;
}
.bg-verde-jagger{
  background: url(../../resources/img/body-background.jpg);
}
.table-bordered {
  border: 1px solid rgba(221, 221, 221, 0.5);
}
.icono-close-table{
  width: 22px;
}
.icono-check-table{
  width: 25px;
}
.table > tbody > tr > td.danger{
  background: transparent;
}
.table > tbody > tr > td.success{
  background: transparent;
}
.panel- {
  background: url(../../resources/img/body-background.jpg);
  background-size: cover;
  border:none;
  margin-bottom: 0px;
}
.boton-more-detail{
  background: #dd5a12;
  border-radius: 5px;
  padding: 10px 40px;
}
.panel- .panel-title{
  margin-top: 10px;
  margin-bottom: 20px;
}
.panel- .panel-body{
  background: url(../../resources/img/body-background.jpg);
  background-size: cover;
}

.btnDwnExcel {
  border: 1px solid #ff895d;
  outline: none;
  background: transparent;
  color: white;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(255,137,93,1);
  -moz-box-shadow: 0px 0px 14px 0px rgba(255,137,93,1);
  box-shadow: 0px 0px 14px 0px rgba(255,137,93,1);
  margin-left: 13px;
  margin-bottom: 10px;
  padding: 6px;
}

.pleca-photo{
  background: #dd5a12;
  border-radius: 5px;
  color: white;
  padding: 10px 30px;
}
.boton-photo{
  border-radius: 50%;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 13px;
  height: 70px;
  width: 70px;
}
#userInfo{
  color: #d7b16f;
  padding: 0 50px;
}
#userInfo .form-control{
  background: transparent;
  color: #d7b16f;
}
#userInfo .dropdown-toggle{
  background: transparent;
  color: #d7b16f;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    right: 25px !important;
}

.left-icon{
  margin-bottom: 10px;
  width: 35px;
}

/* #tableReporter_wrapper{
  overflow-x: scroll;
} */

.filtroHeader, .filtroHeaderOn6{
  margin-top: 25px !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  padding: 5px;
}

#tableReporter .dropdown-menu {
  top: 100% !important;
}
.kpi-reporter{
  color: #d7b16f !important;
}
#tableReporter thead tr:nth-child(2n) th{ 
  background-color: #fb7f3c !important;
}

#tableReporterSales thead tr:nth-child(2n) th{ 
  background-color: #fb7f3c !important;
}

#modalNewVenue, #modalEditVenue{
  position: absolute !important;
}

.closeModal {
  color: white;
}

.thReporter {
  position: relative !important;
  min-width: 100px !important;
  height: 100px !important;
}

.btnHashtag {
  background: #A5D6A7;
  padding: 0.5rem 1rem;
  border-radius: 2.5rem;
  font-size: 20px;
}

.visually-hidden {
  position: absolute;
  left: -100vw;
  
  /* Note, you may want to position the checkbox over top the label and set the opacity to zero instead. It can be better for accessibilty on some touch devices for discoverability. */
}

.btnHashtag>.fa-check {
  color: black !important;
  margin-top: 3px;
  margin-left: 3px;
}

#confirmContainer{  
  min-height: 100%;
  padding: 30px;
  width: 30%;
}

.square {
  height: 200px;
  width: 200px;
  display: inline-block;
  float: none;
  text-align: center;
}

.infoTableMassive>thead>tr>th {
  color: black;
  background-color: darkseagreen;
}

.fa-info-circle {
  font-size: 15px !important;
  color: white !important;
}

.popuptext {  
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 85%; */
  /* left: 50%; */
  margin-left: 15px;
}






.top-60{
  margin-top: 60px;
}
.boton-active{
  background: #dd5a12;
  border-radius: 2px;
  color: white;
  font-size: 10.5px;
  padding: 10px;
  text-align: center;
}
.boton-inactivo{
  background: rgba(0,0,0,0.5);
  border-radius: 2px;
  color: white;
  font-size: 10.5px;
  padding: 10px;
  text-align: center;
}
.font-menu-onOff{
  font-size: 10.5px;
}
.dropdown-toggle{
  background: transparent;
  color: white;
  border: none;
  border-top: 1px solid #dd5a12;
  border-radius: 0px;
  font-weight: lighter;
}
#filtersRow input{
  background: transparent;
  color: white;
  border: none;
  border-top: 1px solid #dd5a12;
  border-radius: 0px;
  font-weight: lighter;
}
.btn-default:hover {
  color: white;
  background-color: rgba(0,0,0,0.3);
  border-color: none;
}
.bt-white{
  border-top: 1px solid rgba(255,255,255,0.7);
}
.card-onTRade{
  background: rgba(0,0,0,0.3);
  border-radius: 5px;
  color: white;
  padding: 10px 5px; 
  text-align: center;
}
.filtro-mapa {
  background: rgba(0,0,0, 0.7);
  border-radius: 5px;
  color: white;
  padding: 10px 5px;
  position: absolute;
  width: 60%;
  margin-left: 20%;
  text-align: center;
}
.seccion-mapa {
  padding-top: 100px !important;
  padding-bottom: 18px !important;
  height: 100% !important;
}
.card-onTRade strong{
  color: #dd5a12;
  font-size: 13px;
}
.cifra{
  color: #e9e5c8;
  font-size: 50px;
  font-weight: bolder;
}
#chartdiv {
width: 100%;
height: 300px;
}
#chartdiv2 {
width: 100%;
height: 300px;
}
#chartdiv3 {
width: 100%;
height: 300px;
}
#chartdiv4 {
width: 100%;
height: 250px;
}

.izquierda-tabla p{
  padding-bottom: 10px;
}
table.dataTable thead .sorting:after, .sorting_desc:after, .sorting_asc:after{
  background-size: 20px 18px;
  width: 20px;
  height: 20px;
  content: "" !important;
  opacity: 1 !important;
  top: 9px !important;

}
.odd{
  background: #e9e4c7;
  padding: 5px;
}
.odd-alt{
  background: #e8c17e;
  padding: 5px;
}
.even{
  background: #ffffff;
  padding: 5px; 
}
.even-alt{
  background: #c0e9ba;
  padding: 5px; 

}
table tbody tr td {
  color: #2d2d2d;
  font-size: 12px;
  border: 1px solid white;
  padding: 9px !important;
}
.bb-white{
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}

#example2_wrapper row:first-child{
  display: none;
}
.th-1{
  background: #e8c17e !important; 
  color: white;
  font-weight: bolder ;
  text-align: center;
  padding-top: 10px;
}
.th-2{
  background: #dd5a16 !important;
  color: white;
  font-weight: bolder ;
  text-align: center;
  padding-top: 10px;
}
.th-3{
  background: #3b5138 !important;
  color: white;
  font-weight: bolder ;
  text-align: center;
  padding-top: 10px;
}


:root {
--pulseSize: 8px;
--blue: rgba(0, 182, 39, 0.65);
--amarillo: #ff9a02;
--rojo: rgba(236, 11, 11, 0.65);
--transparentBlue: rgba(95, 139, 250, 0);
}

.pulse-verde {
  width: var(--pulseSize);
  height: var(--pulseSize);
  border-radius: 50%;
  background: var(--blue);
  box-shadow: 0 0 0 var(--blue);
  animation: pulsing 2s infinite;
  transition: all 0.2s;
  cursor: pointer;
  position: absolute;
  margin-top: 5px;
}

.pulse-verde-centered {
	width: var(--pulseSize);
  height: var(--pulseSize);
  box-shadow: 0 0 0 var(--blue);
  border-radius: 50%;
  background: var(--blue);
	animation: pulsing 2s infinite;
	transition: all 0.2s;
	cursor: pointer;
  margin: auto;
	margin-top: 5px;
}



.pulse-verde:hover {
--blue: rgba(209, 167, 160, 0.65);
--transparentBlue: rgba(209, 167, 160, 0);
}

.pulse-verde:active {
transform: scale(1.5);
}

.pulse-rojo {
	width: var(--pulseSize);
  	height: var(--pulseSize);
	box-shadow: 0 0 0 var(--rojo);
    border-radius: 50%;
	background: var(--rojo);
	animation: pulsing 2s infinite;
	transition: all 0.2s;
	cursor: pointer;
	position: absolute;
	margin-top: 5px;
}

.pulse-rojo-centered {
	width: var(--pulseSize);
  height: var(--pulseSize);
	box-shadow: 0 0 0 var(--rojo);
  border-radius: 50%;
	background: var(--rojo);
	animation: pulsing 2s infinite;
	transition: all 0.2s;
	cursor: pointer;
  margin: auto;
	margin-top: 5px;
}

.pulse-amarillo {
  width: var(--pulseSize);
  height: var(--pulseSize);
  border-radius: 50%;
  background: var(--amarillo);
  box-shadow: 0 0 0 var(--amarillo);
  animation: pulsing 2s infinite;
  transition: all 0.2s;
  cursor: pointer;
  position: absolute;
  margin-top: 5px;
}

.pulse-amarillo-centered {
	width: var(--pulseSize);
  height: var(--pulseSize);
  box-shadow: 0 0 0 var(--amarillo);
  border-radius: 50%;
  background: var(--amarillo);
	animation: pulsing 2s infinite;
	transition: all 0.2s;
	cursor: pointer;
  margin: auto;
	margin-top: 5px;
}

.pulse-amarillo:hover {
--amarillo: rgba(209, 167, 160, 0.65);
--transparentAmarillo: rgba(174, 192, 8, 0);
}
.pulse-rojo:hover {
--rojo: rgba(252, 41, 34, 0.65);
--transparentRojo: rgba(174, 192, 8, 0);
}

.pulse-amarillo:active {
transform: scale(1.5);
}

@keyframes pulsing {
from {
  box-shadow: 0 0 0 0 var(--rojo);
}
70% {
  box-shadow: 0 0 0 var(--pulseSize) var(--transparentRojo);
}
to {
  box-shadow: 0 0 0 0 var(--transparentRojo);
}
}

.nav {
  background-color: rgb(22 44 24);
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transition: top 0.5s ease;
  box-shadow: 0 0 10px black;
  transition: background-color 0.3s ease;
  transition: all 300ms cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
  transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
  height: 100px;
  opacity: 1;
  z-index: 1000;
}
.scrolled {
  background-color: rgb(22 44 24);
  height: 100px;
  opacity: 1;
  transition: all 400ms cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
  transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
  z-index: 100;
}
.logo-submenu{
  width: 70px;
  margin-top: 15px;
}
#filtersRow{
  background: transparent;
  padding: 0px;
}
.texto-rojo{
  color: #FF5A2A;
}
.texto-verde{
  color: #FF5A2A;
}
.texto-dorado{
  color: #d1af66;
}
.angle-down{
  float: right;
}
.icon-envelop{
  margin-top: -4px;
}
.icon-user{
  margin-top: 5px;
}
.notificacion-user{
  position: absolute;
  background: #dd5a12;
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  font-size: 10px;
  top: -5px;
  left: 30px;
}
.notificacion-envelop{
  position: absolute;
  background: #dd5a12;
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  font-size: 10px;
  top: -5px;
  left: 40px;
}
.add-grafica{
  margin-right: 15px;
  margin-top: 25px;
  position: absolute;
  right: 10px;
  width: 70px;
}
.add-grafica img{
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  box-shadow: 2px 2px 18px 0px rgba(221,90,18,0.75);
-webkit-box-shadow: 2px 2px 18px 0px rgba(221,90,18,0.75);
-moz-box-shadow: 2px 2px 18px 0px rgba(221,90,18,0.75)
}
.boton-venueKpi{
  color:  #d8b06d;
}
.texto-foot-table{
  color: #e3c37a;
  font-size: 12px;
}
.footer-table{
  text-align: left;
  padding-left: 10px;
}
.footer-table img{
  margin-right: 5px;
  width: 15px;
}
/*Side nav*/
#sidebar {
  background: #122514;
  width: 82%; 
  position: absolute;
  height: 100%;
  top: 0;
  right: -82%;
  transition: right 0.5s ease;
  color: white;
}

#sidebar.visible {
right: 0px;
transition: right 0.7s ease;
}
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  border-color: none;
}
.grafica-menu{
  width: 30%;
  margin: 13px 0;
}
.border-grafica{
  border-right: 1px solid rgba(255,255,255,0.1);
}
.rotar-grafica{
  transform: rotate(90deg);
}
.closer-card{
  position: absolute;
  right: 10px;
  width: 25px;
}
.closer-card img{
  width: 25px;    
}
.subtitulo-off{
  color: white;
  font-size: 1.5em;
}

.icon-popup{
  vertical-align: middle;
  width: 15px;
}
.cell-transparente{
  background: transparent;
}
.icon-cell{
  margin: 0 10px;
  vertical-align: baseline;
}
.icon-cell span{
  font-size: 15px;
}
.icon-cell img{
  vertical-align: middle;
  width: 13px;
}
.breadcrumb-item{
  color: #d8b06d;
}
.breadcrumb-item-active{
  color: #746a5b;
}
.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: transparent;
  border-radius: 4px;
}
.title-popover {
position: relative;
z-index: 1;
/* Hover styling */
/* Popover Styling */
}
.title-popover:hover {
/* Active Popover on Hover */
}
.title-popover:hover:after {
display: block;
}
.title-popover:after {
background: white;
display: none;
content: attr(title);
position: absolute;
top: 0px;
left: 30px;
font-size: 13px;
z-index: -1;
padding: 0.3em 0.5em 0.3em 120%;
border-radius: 3px;
box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.75);
color:black;
white-space: nowrap;
}

.input-datepicker[readonly],
.input-datepicker {
  background: transparent;
  color: white;
  border: 1px solid #dd5a12;
}
.plan-annual{
  color: #dd5a12;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 5px;
}

.fw-bold {
  font-weight: bold !important; 
}
.fw-bolder {
  font-weight: bolder !important;
}



/* Para ocultar las columnas y no desaparecer del dom */
/* Usado para tabla de visitas */
th.hide_me,
td.hide_me {
    display: none;
}


.filtrosReporter{
  margin-left: 270px;
  margin-top: 65px;
  padding: 10px 20px;
}
.calendarReporter{
  background: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  border-bottom: 1px solid #dd5a12;
  border-radius: 0px;
  text-align: center;
}
.calendarReporter:focus{
  border-color: rgb(221, 90, 18) !important;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(221, 90, 18 / 60%) !important;
}
.fullScreen{
  transition: all 1000ms cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
  transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
}
.left-icon{
  transition: all 1000ms cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
  transition-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); /* custom */
}
.clickCollapse{
  text-align: left;
  padding: 0px 10px;
}
.clickCollapse img{
  float: right;
  width: 20px;
}
.tituloReporter{
  background: #dd5a12;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  text-align: center; 
  color: white; 
  padding: 10px; 
}

